import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { addToCart } from '../utils/addToCart';
import loadable from '@loadable/component';

const Helmet = loadable(() => import('react-helmet'));

const DirectToCheckout = props => {
  const {
    data,
    path,
    location: { href: funnelUrl }
  } = props;
  const cartDetails = data.allBuilderModels.oneDirectToCheckout.data;
  const {
    currentCategory,
    currentTubIndex,
    frequency,
    bumpOffer
  } = cartDetails;
  const funnelPageData = data.funnelPageData.productSelector;
  const { discounts, products, bumpoffers } =
    currentCategory === 'subscriptions'
      ? funnelPageData.subscription
      : funnelPageData.onetime;
  const [product] = products.filter((_, index) => index === currentTubIndex);
  const extraData = JSON.parse(funnelPageData.extra);
  const bumpOfferIndexes = bumpOffer?.map(b => b.bumpOfferIndex) || [];
  const bumpOfferData = bumpoffers.filter((_, index) =>
    bumpOfferIndexes.includes(index)
  );

  Object.assign(product, {
    schedule: frequency || '1:month'
  });

  Object.assign(extraData, {
    multiple_bump_offer: bumpOfferData
  });

  useEffect(() => {
    addToCart(product, discounts, extraData, {}, path, funnelUrl);
  }, [product, discounts, extraData, path, funnelUrl]);

  return (
    <>
      <Helmet title={`Please wait...`} />
      <div>please wait...</div>
    </>
  );
};

export default DirectToCheckout;

export const query = graphql`
  query($funnelId: String!) {
    allBuilderModels {
      oneDirectToCheckout(query: { id: $funnelId }) {
        data {
          bumpOffer
          currentCategory
          currentTubIndex
          frequency
        }
      }
    }

    funnelPageData(funnelId: { eq: $funnelId }) {
      ...FunnelDataFragment
    }
  }
`;
